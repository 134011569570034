//app版本管理
import { axios } from '@/utils/request'

/*
 * @Description: app版本分页查询
 * @Version: 
 * @Author: 陈莉莉
 * @Date: 2022-05-12
 */
export function findAppVersionPageList(parameter){
    return axios({
        url: "/appVersion/page",
        method: "post",
        params:parameter.page,
        data: parameter.queryParam
    })
}

/**
 * @Description: app版本新增
 * @param {*} parameter 
 * @Author: 陈莉莉
 * @Date: 2022-05-12
 * @returns 
 */
export function addAppVersion(parameter){
    return axios({
        url: "/appVersion/addItem",
        method: 'post',
        data: parameter
    })
}

/**
 * @Description: app版本编辑
 * @param {*} parameter 
 * @Author: 陈莉莉
 * @Date: 2022-05-12
 * @returns 
 */
 export function editAppVersion(parameter){
    return axios({
        url: "/appVersion/edit",
        method: 'post',
        data: parameter
    })
}

/**
 * @Description: app版本删除
 * @param {*} parameter 
 * @Author: 陈莉莉
 * @Date: 2022-05-12
 * @returns 
 */
export function deleteAppVersion(parameter){
    return axios({
        url: "/appVersion/delete",
        method: "post",
        data: parameter
    })
}

/**
 * @Description: 获取app版本号详情
 * @param {*} parameter 
 * @Author: 陈莉莉
 * @Date: 2022-05-12
 * @returns 
 */
 export function getAppVersionDetail(parameter){
    return axios({
        url: "/appVersion/detail",
        method: "get",
        params: parameter
    })
}