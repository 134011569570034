<template>
  <a-modal
    title="编辑app版本号"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="app版本" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input
            placeholder="请输入版本号，如1.10"
            v-decorator="['version', { rules: [{ required: true, message: '请输入版本号，如1.0.0' }] }]"
          />
        </a-form-item>
        <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-select
            placeholder="类型"
            v-decorator="['type', { rules: [{ required: true, message: '请选择是否强制更新' }] }]"
          >
            <a-select-option :value="1" label="APP整包更新">APP整包更新</a-select-option>
            <a-select-option :value="2" label="资源更新">资源更新</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="下载地址" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-upload
            @change="handleChange"
            :customRequest="showFigureRequest"
            :fileList="fileList"
            v-decorator="['url']"
          >
            <div
              style="
                display: flex;
                height: 26px;
                line-height: 26px;
                font-size: 14px;
                padding: 0 12px;
                border: 1px solid #cecece;
                border-radius: 4px;
              "
            >
              <div style="color: #999" class="ant-upload-text">请上传</div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-item label="强制更新" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-select
            placeholder="是否强制更新"
            v-decorator="['isForce', { rules: [{ required: true, message: '请选择是否强制更新' }] }]"
          >
            <a-select-option :value="1" label="强制更新">强制更新</a-select-option>
            <a-select-option :value="2" label="不强制更新">不强制更新</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { editAppVersion, getAppVersionDetail } from '@/api/modular/mallLiving/appVersion'
import COS from 'cos-js-sdk-v5'
import { getUploadScreat } from '@/api/modular/mallLiving/uploadScreat'
import detailsVue from '../log/oplog/details.vue'
import { uploadFile } from '@/api/modular/mallLiving/uploadFile'
export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
      },
      cos: null,
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      fileList: [],
      editId: '',
      delFlag: 1,
    }
  },
  methods: {
    // 初始化方法
    edit(record) {
      let that = this
      let msgData = sessionStorage.getItem('upload-screat-msg')
      if (msgData) {
        msgData = JSON.parse(sessionStorage.getItem('upload-screat-msg'))
        var etime = new Date().getTime()
        if (msgData.expiredTime * 1000 < etime + 20 * 60 * 1000) {
          this.getUploadScreat()
        } else {
          let data = {
            TmpSecretId: msgData.tmpSecretId,
            TmpSecretKey: msgData.tmpSecretKey,
            XCosSecurityToken: msgData.sessionToken,
            StartTime: msgData.startTime,
            ExpiredTime: msgData.expiredTime,
          }
          that.cos = new COS({
            getAuthorization: function (options, callback) {
              callback(data)
            },
          })
        }
      } else {
        this.getUploadScreat()
      }
      this.editId = record.id
      this.visible = true
      getAppVersionDetail({ id: record.id }).then((res) => {
        if (res.success) {
          let detail = res.data
          this.delFlag = detail.delFlag
          this.form.setFieldsValue({
            id: detail.id,
            version: detail.version,
            isForce: detail.isForce * 1,
            type: detail.type ? detail.type * 1 : 1,
          })
          let urls = []
          urls.push({
            url: res.data.url,
            uid: 1001,
            name: res.data.url.split('/')[res.data.url.split('/').length - 1],
            status: 'done',
          })
          this.fileList = urls
        }
      })
    },

    getUploadScreat() {
      let that = this
      getUploadScreat().then((res) => {
        that.uploadOption = res.data
        let data = {
          TmpSecretId: res.data.tmpSecretId,
          TmpSecretKey: res.data.tmpSecretKey,
          XCosSecurityToken: res.data.sessionToken,
          StartTime: res.data.startTime,
          ExpiredTime: res.data.expiredTime,
        }
        sessionStorage.setItem('upload-screat-msg', JSON.stringify(res.data))
        that.cos = new COS({
          getAuthorization: function (options, callback) {
            callback(data)
          },
        })
      })
    },

    formatFileName() {
      var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        a = t.length,
        n = ''

      for (var i = 0; i < 10; i++) n += t.charAt(Math.floor(Math.random() * a))
      return n + new Date().getTime()
    },

    showFigureRequest(filedata) {
      let that = this
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          var percentage = Math.floor(p * 100)
        },
      })
        .then((res) => {
          console.log('res', res)
          if (res.res.statusCode == 200) {
            var list = res.res.requestUrls[0].split('?')
            if (list.length > 0) {
              this.picture = list[0]
              this.pictureLoading = false
              that.fileList = []
              this.fileList.push({
                url: list[0],
                uid: this.fileList.length + 1,
                name: filedata.file.name,
                status: 'done',
              })
            }
          }
        })
        .finally(() => {
          this.pictureLoading = false
        })
    },

    handleChange({ fileList }) {
      console.log(fileList)
      this.fileList = fileList
      //如果轮播图都删除了，把tempList置空
      this.$nextTick(() => {
        if (this.form.getFieldValue('tempList') && this.form.getFieldValue('tempList').fileList.length == 0) {
          this.form.setFieldsValue({
            tempList: null,
          })
        }
      })
    },

    handleSubmit() {
      const {
        form: { validateFields },
      } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          if (this.fileList.length == 0) {
            this.$message.error('请上传apk地址')
            return
          }
          values.url = this.fileList[0].url
          values.id = this.editId
          values.delFlag = this.delFlag
          editAppVersion(values)
            .then((res) => {
              this.confirmLoading = false
              if (res.success) {
                this.$message.success('编辑成功')
                this.handleCancel()
                this.$emit('ok', values)
              } else {
                this.$message.error('编辑失败：' + res.message)
              }
            })
            .finally((res) => {
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.visible = false
    },
  },
}
</script>
