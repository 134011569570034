<template>
  <!-- 代理商列表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="24">
                <!-- 按钮 -->
                <a-col :md="6" :sm="24">
                  <a-button @click="$refs.addForm.add()" style="margin-right: 14px">新增</a-button>
                  <a-button type="primary" @click="initTableData">刷新</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table :columns="columns" :data-source="data" :scroll="{}" :loading="loading" :pagination="false">
            <!-- 视频列表状态插槽 -->
            <span slot="isForce" slot-scope="text">
              <span :style="text == 1 ? 'color: red' : 'color: green'">{{
                text == 1 ? '强制更新' : '非强制更新'
              }}</span>
            </span>
            <span slot="categoryNames" slot-scope="text">
              {{ text ? (text.split('').length > 20 ? text.substring(0, 20) + '...' : text) : '' }}
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.editForm.edit(record)">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => confirmDeleteAppVersion(record)">
                <a>删除</a>
              </a-popconfirm>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <add-form ref="addForm" @ok="handleOk"></add-form>
    <edit-form ref="editForm" @ok="handleOk"></edit-form>
  </div>
</template>

<script>
import { findAppVersionPageList, deleteAppVersion } from '@/api/modular/mallLiving/appVersion'
import addForm from './addForm.vue'
import editForm from './editForm.vue'
const columns = [
  {
    title: '序号',
    width: '80px',
    align: 'center',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '版本号',
    dataIndex: 'version',
    key: 'name',
    width: '120px',
    align: 'center',
  },
  {
    title: '下载地址',
    dataIndex: 'url',
    key: 'url',
    align: 'center',
  },
  {
    title: '类型',
    dataIndex: 'type',
    align: 'center',
    customRender: (text) => `${text == 2 ? '资源更新' : 'APP整包更新'}`,
  },
  {
    title: '强制更新',
    dataIndex: 'isForce',
    align: 'center',
    width: '120px',
    scopedSlots: { customRender: 'isForce' },
  },
  {
    title: '更新时间',
    width: '180px',
    dataIndex: 'updateTime',
    align: 'center',
  },
  {
    title: '操作',
    width: '130px',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      recordItem: null,
      loading: false,
      queryParam: {},
      videoTypeList: [], //视频分类数据
      provinceList: [], //省份列表
      cityList: [], //市区列表
    }
  },
  components: {
    addForm,
    editForm,
  },
  mounted() {
    this.$message.info('一定不要忘记更新字典表里安卓下载地址')
    this.initTableData()
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },

    getList() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: this.queryParam,
      }
      findAppVersionPageList(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },

    /**
     * @params reord
     * 删除版本
     */
    confirmDeleteAppVersion(record) {
      deleteAppVersion({ id: record.id }).then((res) => {
        this.initTableData()
        this.$message.success('版本已删除')
      })
    },
    handleOk() {
      this.initTableData()
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 20px;
  height: 20px;
}
</style>
